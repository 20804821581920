import React from "react"
import PropTypes from "prop-types"

import HorizontalTwoColumns from "../../components/Bulma/HorizontalTwoColumns"
import HorizontalThreeColumns from "../../components/Bulma/HorizontalThreeColumns"
import ImageMeta from "../../components/ImageMeta"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"
import { Link } from "gatsby"

function LifestyleFeature(props) {
  if (!props.data.hasThisSection) {
    return <></>
  }

  if (!props.homepage) {
    return (
      <>
        <HorizontalTwoColumns
          className="lifestyle-feature color-back show-phone-only-inverse"
          leftColWidth={3}
          rightColWidth={3}
          middleColWidth={1}
          rightColumn={
            <div className="column" data-aos="fade-up" data-aos-duration="1200">
              <Link to={props.data.buttons[0].button.href}>
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={props.data.imageId}
                  data-src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_auto/v1/${props.data.imageId}`}
                  responsive
                />
              </Link>
            </div>
          }
          leftColumn={
            <div
              className="column is-two-fifths community-text-block"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h2>{props.data.heading}</h2>
              <p className="large">{props.data.text}</p>
              <ButtonGroupMap buttons={props.data.buttons} />
            </div>
          }
        />
        <HorizontalTwoColumns
          className="lifestyle-feature color-back show-phone-only-block"
          leftColWidth={2}
          rightColWidth={2}
          middleColWidth={1}
          leftColumn={
            <div className="column">
              <h2>{props.data.heading}</h2>
              <p className="large">{props.data.text}</p>
              <Link to={props.data.buttons[0].button.href}>
                <ImageMeta
                  cloudName="nuvolum"
                  publicId={props.data.imageId}
                  data-src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_auto/v1/${props.data.imageId}`}
                  responsive
                />
              </Link>
              <ButtonGroupMap buttons={props.data.buttons} />
            </div>
          }
          rightColumn={<div></div>}
        />
      </>
    )
  }

  return (
    <>
      <HorizontalThreeColumns
        className="lifestyle-feature color-back show-phone-only-inverse is-vcentered"
        leftColWidth={5}
        rightColWidth={5}
        rightColumn={
          <div className="column" data-aos="fade-up" data-aos-duration="1200">
            <Link to={props.data.right.buttons[0].button.href}>
              <ImageMeta
                cloudName="nuvolum"
                publicId={props.data.right.imageId}
                responsive
              />
            </Link>
            <ButtonGroupMap buttons={props.data.right.buttons} isCentered />
          </div>
        }
        leftColumn={
          <div className="column" data-aos="fade-up" data-aos-duration="1200">
            <Link to={props.data.left.buttons[0].button.href}>
              <ImageMeta
                cloudName="nuvolum"
                publicId={props.data.left.imageId}
                responsive
              />
            </Link>
            <ButtonGroupMap buttons={props.data.left.buttons} isCentered />
          </div>
        }
        middleColumn={
          <div
            className="column lifestyle-feature-text has-text-centered "
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <h2>{props.data.heading}</h2>
            <p className="large">{props.data.text}</p>
          </div>
        }
      />
      <HorizontalThreeColumns
        className="lifestyle-feature color-back  show-phone-only-block"
        leftColWidth={5}
        rightColWidth={5}
        middleColumn={
          <div
            className="column"
            data-aos="fade-up"
            data-aos-duration="1200"
            style={{ marginBottom: 32 }}
          >
            <Link to={props.data.left.buttons[0].button.href}>
              <ImageMeta
                cloudName="nuvolum"
                publicId={props.data.left.imageId}
                responsive
              />
            </Link>
            <ButtonGroupMap buttons={props.data.left.buttons} isCentered />
          </div>
        }
        rightColumn={
          <div className="column" data-aos="fade-up" data-aos-duration="1200">
            <Link to={props.data.right.buttons[0].button.href}>
              <ImageMeta
                cloudName="nuvolum"
                publicId={props.data.right.imageId}
                responsive
              />
            </Link>
            <ButtonGroupMap buttons={props.data.right.buttons} isCentered />
          </div>
        }
        leftColumn={
          <div
            className="column community-text-block"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <h2>{props.data.heading}</h2>
            <p className="large">{props.data.text}</p>
          </div>
        }
      />
    </>
  )
}

export default LifestyleFeature
