import React, { useState } from "react"
import ImageMeta from "../ImageMeta"
import Layout from "../layout"
import Seamless from "../Seamless"
import { SharedStateProvider } from "../SharedState/PageSharedState"
import SEO from "../seo"

import TheaterVideo from "@bit/azheng.joshua-tree.theater"
import Button from '@bit/azheng.joshua-tree.button';
import ReviewRow from "../ReviewRow"
import CloseIcon from "../CloseIcon"
import CTA from "../CTA/CTA"
import GetStarted from "../CTA/GetStarted"
import HeadingParagraphButton from "../CTA/HeadingParagraphButton"

import { Dialog, setHotkeysDialogProps } from "@blueprintjs/core"

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer";

var classNames = require("classnames")

export default function Modal(props) {
    var modalClass = classNames({
        "c-menu": true,
        "dialog-one": true,
    })

    const [dialogOpen, setDialogOpen] = useState(false);
    const [activeText, setActiveText] = useState(
        {
            modalHeading: props.modal[0].modalHeading,
            modalBlurb: props.modal[0].modalBlurb,
            youtube: props.modal[0].youtube
        }
    );

    function toggleDialog(activeText) {
        setDialogOpen(!dialogOpen)
        setActiveText(activeText)
    }


    return (
        <div className="has-text-centered">
            <Dialog
                canOutsideClickClose={true}
                enforceFocus={true}
                autoFocus={true}
                canEscapeKeyClose={true}
                isOpen={dialogOpen}
                usePortal={true}
                onClose={toggleDialog}
            >
                <div className="has-text-centered dialog-content">
                    <CloseIcon onClick={toggleDialog} />
                    <h3>{activeText.modalHeading}</h3>
                    <p className="large">
                        {activeText.modalBlurb}
                    </p>
                    {activeText.youtube && <TheaterVideo
                        videoUrl={`https://www.youtube.com/watch?v=${activeText.youtube}`}
                        controls={true}
                        playing={true}
                        onlyButton={true}
                    />}
                    {!activeText.youtube && <div style={{ height: "80px" }}></div>}
                </div>
            </Dialog>
            {props.modal.map((button) => {
                let activeText = {
                    modalHeading: button.modalHeading,
                    modalBlurb: button.modalBlurb,
                    youtube: button.youtube
                }
                return (
                    <a key={button.buttonLabel} className="standard-button" onClick={() => toggleDialog(activeText)}>{button.buttonLabel}</a>
                )
            }
            )}
        </div>
    )
}
